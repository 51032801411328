import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import * as AppConfigs from '../app.constants';
import {pdx_StateRequiredCountries} from "../app.constants";

@Component({
  selector: 'addproduct',
  templateUrl: './addproduct.component.html'
})
export class AddproductComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private resourceService: ResourceService, private userService: UserService) {};
  public docTitle: string = '';
  public productType: string = '';
  public planType: string = '';
  public appLanguage: string = '';
  public appLabel: any = {};
  public pmcProductDetails: any = {};
  public countryList: any = [];
  public stateList: any = [];
  public countryCode: string = '';
  public countryIsoCode2: string = '';
  public inprogress: boolean = false;
  public notificationMsg: string = '';
  public welcomeMsg: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  addproductForm: FormGroup;
  company: FormControl;
  country: FormControl;
  address1: FormControl;
  address2: FormControl;
  zip: FormControl;
  city: FormControl;
  state: FormControl;
  industry: FormControl;
  workPhone: FormControl;
  recaptcha: FormControl;
  public isCaptchaRequired: string = 'false';
  public isResourceLoaded: boolean = false;
  public reCaptchaSiteKey: string = '';

  async ngOnInit(): Promise<void> {
    this.productType = this.activatedRoute.snapshot.paramMap.get('productType');
    const externalProductList = ['geotax', 'sdm', 'mipro', 'geocoding', 'software-apis'];
    if (externalProductList.indexOf(this.productType) < 0) {
      window.location.href = '/undefined';
    }
    this.activatedRoute.queryParams.subscribe(
      params => {
        if (params.plan) {
          if (this.productType === 'sdm' || this.productType === 'mipro') {
            window.location.href = '/addproduct/' + this.productType;
          } else {
            if (params.plan !== 'free' || !this.userService.ifUserLoggedIn()) {
              window.location.href = '/addproduct/' + this.productType;
              +'?plan=free';
            }
            this.planType = 'free';
          }
        } else {
          if (this.productType !== 'sdm' && this.productType !== 'mipro') {
            window.location.href = '/addproduct/' + this.productType;
            +'?plan=free';
          } else if (!this.userService.ifUserLoggedIn()) {
            window.location.href = '/addproduct/' + this.productType;
          }
        }
      }
    );
    this.resourceService.getresources().subscribe(
      success => {
        this.isResourceLoaded = true;
        this.isCaptchaRequired = success.isCaptchaRequired;
        this.reCaptchaSiteKey = success.reCaptchaSiteKey;
        this.createFormControls();
        this.createForm();
        if (this.productType === 'sdm') {
          this.addproductForm.get('country').valueChanges.subscribe(country => {
            this.updateStateValidator(country);
          });
        }
      }
    );
    switch (this.productType) {
      case 'geocoding':
        this.docTitle = 'MapMarker';
        break;
      case 'sdm':
        this.docTitle = 'Precisely Data Experience';
        break;
      case 'geotax':
        this.docTitle = 'GeoTAX';
        break;
      case 'mipro':
        this.docTitle = 'MapInfo Pro';
        break;
      default:
        this.docTitle = 'Precisely APIs';
    }
    this.titleService.setTitle(this.docTitle);
    if (this.productType === 'mipro') {
      let appLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
      if (appLang !== null) {
        this.appLanguage = appLang;
      } else {
        this.appLanguage = 'en-US';
        localStorage.setItem('NG_TRANSLATE_LANG_KEY', this.appLanguage);
      }
    } else {
      this.appLanguage = 'en-US';
    }
    this.loadAppLanguage();
    this.resourceService.getPmcProductDetails(this.productType).subscribe(
      success => {
        this.pmcProductDetails = success;
      }
    );
    this.resourceService.getCountries().subscribe(
      success => {
        this.countryList = success;
        if(this.productType !== 'geotax' && this.productType !== 'mipro'){
          this.setCountryCodeStateList();
        }
      }
    );
    let userDetails = await this.userService.getUserInfo();
    this.firstName = userDetails.user.firstName;
    this.lastName = userDetails.user.lastName;
    this.email = userDetails.user.email;
  }

  setTextForProduct = function(msg){
    if(this.productType === 'software-apis'){
      return msg.replace('{{textforproduct}}', ' Precisely APIs ');
    } else if(this.productType === 'geotax'){
      return msg.replace('{{textforproduct}}', ' GeoTAX ');
    } else if(this.productType === 'sdm'){
      return msg.replace('{{textforproduct}}', ' Precisely Data Experience ');
    } else if(this.productType === 'mipro'){
      return msg.replace('{{textforproduct}}', ' MapInfo Pro ');
    } else if(this.productType === 'geocoding'){
      return msg.replace('{{textforproduct}}', ' MapMarker ');
    }
  };
  createFormControls = function(){
    this.company = new FormControl('', [Validators.required, Validators.maxLength(35)]);
    if(this.productType !== 'geotax' && this.productType !== 'mipro') {
      if (this.productType === 'sdm') {
        this.country = new FormControl('United States', [Validators.required]);
        this.state = new FormControl('');
      } else {
        this.country = new FormControl('United States');
        this.address1 = new FormControl('', [Validators.required]);
        this.address2 = new FormControl('');
        this.zip = new FormControl('', [Validators.required]);
        this.city = new FormControl('', [Validators.required]);
        this.state = new FormControl('', [Validators.required]);
        this.workPhone = new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(16), Validators.pattern("^[0-9+()-]*$")]);
      }
    }
    if(this.isCaptchaRequired === 'true'){
      this.recaptcha = new FormControl('', [Validators.required]);
    }
  };
  createForm = function() {
    let formGropuObj = {
      company: this.company
    };
    if(this.isCaptchaRequired === 'true'){
      formGropuObj['recaptcha'] = this.recaptcha;
    }
    if(this.productType !== 'geotax' && this.productType !== 'mipro'){
      formGropuObj['country'] = this.country;
      formGropuObj['state'] = this.state;
      if(this.productType !== 'sdm') {
        formGropuObj['address1'] = this.address1;
        formGropuObj['address2'] = this.address2;
        formGropuObj['zip'] = this.zip;
        formGropuObj['city'] = this.city;
        formGropuObj['workPhone'] = this.workPhone;
      }
    }
    this.addproductForm = new FormGroup(formGropuObj);
  };
  setAppLanguage = function(){
    localStorage.setItem('NG_TRANSLATE_LANG_KEY', this.appLanguage);
    this.loadAppLanguage();
  };
  loadAppLanguage = function(){
    this.resourceService.getAppLanguage(this.appLanguage).subscribe(
      success => {
        this.appLabel = success;
        this.welcomeMsg = this.setTextForProduct(this.appLabel.SUP_AddProductUser);
      }
    );
  };
  submitAddproductForm = function(){
    this.addproductForm.touched = true;
    this.company.touched = true;
    if(this.isCaptchaRequired === 'true'){
      this.recaptcha.touched = true;
    }
    if(this.productType !== 'geotax' && this.productType !== 'mipro' && this.productType !== 'sdm'){
      this.address1.touched = true;
      this.address2.touched = true;
      this.zip.touched = true;
      this.city.touched = true;
      this.state.touched = true;
      this.workPhone.touched = true;
      let addrVal = $('.pb-geo-input-text').val();
      this.address1.setValue(addrVal);
    }
    if(this.productType === 'sdm'){
      this.state.touched = true;
    }
    if(this.addproductForm.valid){
      let userData = {
        addressLine1: '',
        city: '',
        company: this.company.value,
        contactNumber: '',
        countryCode: this.countryIsoCode2,
        email: this.email,
        firstName: this.firstName,
        flowType: 'Add Subscription',
        industry: '',
        lastName: this.lastName,
        locale: this.appLanguage,
        planType: this.planType,
        productType: this.productType,
        securityAns: null,
        securityQues: null,
        state: {
          name: ''
        },
        userType: 'Add Subscription',
        utm_campaign: this.activatedRoute.snapshot.queryParams.utm_campaign || null,
        utm_content: this.activatedRoute.snapshot.queryParams.utm_content || null,
        utm_medium: this.activatedRoute.snapshot.queryParams.utm_medium || null,
        utm_source: this.activatedRoute.snapshot.queryParams.utm_source || null,
        zip: ''
      };
      if(this.isCaptchaRequired === 'true'){
        userData['recaptcha'] = this.recaptcha.value;
      }
      if(this.planType === ''){
        if(this.productType === 'mipro' || this.productType === 'sdm'){
          userData.planType = 'free';
        }
      }
      if(this.productType !== 'geotax' && this.productType !== 'mipro'){
                userData.state.name = this.state.value;
        for(let i=0; i<this.stateList.length; i++){
          if(this.stateList[i].name === this.state.value){
            if(this.stateList[i].isoCode){
              userData.state['isoCode'] = this.stateList[i].isoCode;
            }
          }
        }
        if(this.productType !== 'sdm') {
          userData.addressLine1 = this.address1.value;
          userData.zip = this.zip.value;
          userData.city = this.city.value;
          userData.contactNumber = this.workPhone.value;
        }
      }
      this.inprogress = true;
      this.userService.createNewUser(userData).subscribe(
        success => {
          this.addproductForm.reset();
          this.inprogress = false;
          switch(success.statusCode){
            case 202:
              window.location.href = '/thanks/'+this.productType+'?resultTag=false';
              break;
            default:
              window.location.href = '/thanks/'+this.productType+'?resultTag=true&scenario=addProductSuccess';
              break;
          }
        },
        error => {
          this.inprogress = false;
          switch(error.status){
            case 400:
              this.notificationMsg = 'We’re having issue in processing your request. Please try again.';
              this.redMsg = true;
              this.greenMsg = false;
              break;
            default:
              window.location.href = '/thanks/'+this.productType+'?resultTag=false';
              break;
          }
        }
      );
    }
  };
  setCountryCodeStateList = function(){
    $('.pb-geo-input-text').val('');
    if(this.productType !== 'sdm') {
      this.address1.setValue('');
      this.address2.setValue('');
      this.zip.setValue('');
      this.city.setValue('');
    }
    this.state.setValue('');
    for(let i=0; i<this.countryList.length; i++){
      if(this.countryList[i].name === this.country.value){
        this.countryCode = this.countryList[i].isoCode3;
        this.countryIsoCode2 = this.countryList[i].isoCode2
        this.stateList = this.countryList[i].states;
        this.stateList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
    }
  };
  handleGeosearchData = function(data){
    this.address1.setValue(data.address.mainAddressLine);
    this.address2.setValue('');
    this.zip.setValue(data.address.postCode);
    this.city.setValue(data.address.areaName3);
    let stateName = '';
    if(this.countryCode === 'USA'){
      for(var i=0; i<AppConfigs.americanStates.length; i++){
        if(data.address.areaName1 === AppConfigs.americanStates[i].isoCode){
          stateName = AppConfigs.americanStates[i].name;
        }
      }
    }
    this.state.setValue(stateName);
    setTimeout(() => {
      $('.pb-geo-input-text').val(data.address.mainAddressLine);
    }, 100);
  };
  setAddress1 = function(){
    let addrVal = $('.pb-geo-input-text').val();
    this.address1.setValue(addrVal);
  };

  private updateStateValidator(country: string): void {
    const stateControl = this.addproductForm.get('state')
    // Reset validators
    stateControl.clearValidators();
    if(pdx_StateRequiredCountries.includes(country)) {
      stateControl.setValidators([Validators.required]);
    }
    // Update the validity status
    stateControl.updateValueAndValidity();
  }

  isStateVisible(): boolean {
    const selectedCountry: string = this.country.value
    let isVisible = false;
    isVisible = this.productType !== 'sdm' ||
      (this.productType === 'sdm' &&
        pdx_StateRequiredCountries.includes(selectedCountry));
    return isVisible;
  }
}
