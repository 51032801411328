import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { PbdsDatavizModule } from '@precisely/design-system';
import { PbdsHeaderShadowModule } from '@precisely/design-system';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from './service/resource.service';
import { UserService } from './service/user.service';
import { TableModule } from 'primeng/table';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SignupComponent } from './signup/signup.component';
import { GeosearchComponent } from './geosearch/geosearch.component';
import { GeosearchService } from './geosearch/geosearch.service';
import { ThanksComponent } from './thanks/thanks.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import {RedirectGuard} from "./redirectGuard";

const CONFIGURE_STATES = [
  { path: 'undefined', component: PagenotfoundComponent },
  // added RedirectGuard to redirect to the precisely contact link without loading the component.
  { path: 'signup/:productType', canActivate: [RedirectGuard], component: SignupComponent },
  { path: 'thanks/:productType', canActivate: [RedirectGuard], component: ThanksComponent },
  { path: 'upgrade/plans/:productType', component: UpgradeComponent },
  { path: 'addproduct/signup/:productType', canActivate: [RedirectGuard], component: AddproductComponent },
  // This is the WILDCARD CATCH-ALL route that is scoped to the entire application.
  // It will catch any request that is not matched by an earlier route definition.
  // Always make sure this is last route
  { path: '**', redirectTo: '/undefined' }
];

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, PagenotfoundComponent, SignupComponent, GeosearchComponent, ThanksComponent, UpgradeComponent, AddproductComponent],
  imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule, FormsModule, RecaptchaModule, RecaptchaFormsModule, PbdsDatavizModule, PbdsHeaderShadowModule, HttpClientModule, RouterModule.forRoot(CONFIGURE_STATES, { relativeLinkResolution: 'legacy' }), NgbModalModule, NgbModule, TableModule, ToastrModule.forRoot({ maxOpened: 1, autoDismiss: true, enableHtml: true }), BrowserAnimationsModule, NgSelectModule],
  providers: [CookieService, ToastrService, ResourceService, UserService, GeosearchService, { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings }],
  bootstrap: [AppComponent]
})
export class AppModule { }
